import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage } from 'react-intl'

const switchData = (data, langKey) => {
    // eslint-disable-next-line no-unused-vars
    var posts
    switch (langKey) {
        case '':
            return (posts = data.en)
        case 'ka':
            return (posts = data.ka)
        default:
            return ' '
    }
}

class WorkRoll extends React.Component {
    constructor(props) {
        super(props)
        this.state = { url: '' }
    }

    getUrl() {
        const urlChecker =
            window.location.pathname !== '/work/'
                ? window.location.pathname
                : ''
        this.setState({ url: urlChecker })
    }

    componentDidMount() {
        this.getUrl()
    }

    toObject() {
        const dateForm = this.posts.map(
            ({ node: post }) => post.frontmatter.date
        )
        var rv = {}
        for (var i = 0; i < dateForm.length; ++i) rv[i] = dateForm[i]
        return rv
    }

    render() {
        const { data } = this.props
        const langKey = this.state.url.slice(1, 3)
        const { edges: posts } = switchData(data, langKey)
        return (
            <div className="mt-6">
                {posts &&
                    posts.map(({ node: post }) => (
                        <div className="columns content is-vcentered is-multiline is-border-bottom-solid">
                            <div className="column is-1 is-hidden-touch is-hidden-tablet-only is-hidden-mobile">
                                <h2 className="is-size-4-rem has-text-weight-normal is-title-color">
                                    {post.frontmatter.number}
                                </h2>
                            </div>
                            <div className="column is-4">
                                <h2 className="is-size-4-rem is-size-1-mobile has-text-weight-normal"></h2>
                                <Img
                                    fluid={
                                        post.frontmatter.image.childImageSharp
                                            .fluid
                                    }
                                />
                            </div>
                            <div className="column is-7" key={post.id}>
                                <h2 className="is-size-4-rem is-size-1-mobile is-title-color has-text-weight-normal is-margin-bottom-less">
                                    {post.frontmatter.title}
                                </h2>
                                <h3 className="tag is-dark has-text-weight-medium is-size-6">
                                    {post.frontmatter.projectIndustry}
                                </h3>
                                <p className="has-text-weight-medium is-subtitle-color">
                                    {post.frontmatter.description}
                                </p>
                                <Link
                                    className="has-text-primary is-size-4 mt-4"
                                    to={post.fields.slug}
                                >
                                    <FormattedMessage id="work.case.cta" />
                                </Link>
                            </div>
                        </div>
                    ))}
            </div>
        )
    }
}

WorkRoll.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

// eslint-disable-next-line react/display-name
export default () => (
    <StaticQuery
        query={graphql`
            query WorkRollQuery {
                site {
                    siteMetadata {
                        title
                        languages {
                            langs
                            defaultLangKey
                        }
                    }
                }
                en: allMarkdownRemark(
                    sort: { order: ASC, fields: [frontmatter___number] }
                    filter: {
                        frontmatter: {
                            templateKey: { eq: "work-post" }
                            lang: { regex: "/(en|any)/" }
                        }
                    }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 150)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                projectIndustry
                                description
                                templateKey
                                lang
                                imageAlt
                                number
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 500) {
                                            ...GatsbyImageSharpFluid_noBase64
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ka: allMarkdownRemark(
                    sort: { order: ASC, fields: [frontmatter___number] }
                    filter: {
                        frontmatter: {
                            templateKey: { eq: "work-post" }
                            lang: { regex: "/(ka|any)/" }
                        }
                    }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 150)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                projectIndustry
                                description
                                templateKey
                                lang
                                imageAlt
                                number
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 500) {
                                            ...GatsbyImageSharpFluid_noBase64
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => <WorkRoll data={data} />}
    />
)
